import { useEffect, useState } from 'react';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';
import Hotjar from '@hotjar/browser';

import ThemeCustomization from 'themes';
import { dispatch } from 'store';
import { getDashboard } from 'store/slices/menu';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// const siteId = 3609670; //DES
const siteId = 3690514; //PRD
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

// ==============================|| APP ||============================== //

const App = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getDashboard()).then(() => {
            setLoading(true);
        });
    }, []);

    if (!loading) return <Loader />;

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;


// import { useEffect, useState } from 'react';
// import ThemeCustomization from 'themes';
// import Loader from 'ui-component/Loader';
// import Hotjar from '@hotjar/browser';

// // Material-UI imports
// import { Box, Typography, Container, Paper } from '@mui/material';
// import { styled } from '@mui/material/styles';
// // import logo from './src/assets/images/logo.svg';
// const siteId = 3690514; //PRD
// const hotjarVersion = 6;
// Hotjar.init(siteId, hotjarVersion);


// // Estilos personalizados
// const MaintenanceContainer = styled(Box)(({ theme }) => ({
//     minHeight: '100vh',
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: theme.palette.background.default,
//     padding: theme.spacing(3)
// }));

// const Logo = styled('img')({
//     maxWidth: 200,
//     marginBottom: '2rem',
//     content: `url(https://firebasestorage.googleapis.com/v0/b/aes-app-755d2.appspot.com/o/assets%2Flogo_aes.svg?alt=media&token=71dfb2d1-468f-468e-8c2d-c87d9c42a9ad)`
// });

// const ContactPaper = styled(Paper)(({ theme }) => ({
//     padding: theme.spacing(3),
//     textAlign: 'center',
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius
// }));

// // Componente de mantenimiento
// const MaintenancePage = () => {
//     return (
//         <MaintenanceContainer>
//             <Container maxWidth="sm">
//                 <Box sx={{ textAlign: 'center' }}>
//                 <Logo
//                         src="/static/media/logo.svg" 
//                         alt="AES El Salvador"
//                     />
                    
//                     <Typography 
//                         variant="h1" 
//                         sx={{ 
//                             color: 'primary.main',
//                             marginBottom: 2,
//                             fontSize: { xs: '2rem', sm: '2.5rem' }
//                         }}
//                     >
//                         Estamos en mantenimiento
//                     </Typography>
                    
//                     <Typography 
//                         variant="h6" 
//                         sx={{ 
//                             color: 'text.secondary',
//                             marginBottom: 4
//                         }}
//                     >
//                         Estamos realizando mejoras en nuestros sistemas para brindarte un mejor servicio. 
//                         Disculpa las molestias ocasionadas.
//                     </Typography>
                    
//                     <Typography 
//                         variant="h5" 
//                         sx={{ 
//                             color: 'primary.main',
//                             marginBottom: 4,
//                             fontWeight: 500
//                         }}
//                     >
//                        El servicio se reanudará en breve.
//                     </Typography>
                    
//                     <ContactPaper elevation={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Para consultas o emergencias, contáctanos:
//                         </Typography>
//                         <Typography variant="body1" gutterBottom>
//                             Tel y WhatsApp: 2506-9000
//                         </Typography>                        
//                     </ContactPaper>
//                 </Box>
//             </Container>
//         </MaintenanceContainer>
//     );
// };

// // ==============================|| APP ||============================== //

// const App = () => {
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setLoading(false);
//         }, 1000);
//         return () => clearTimeout(timer);
//     }, []);

//     if (loading) return <Loader />;

//     return (
//         <ThemeCustomization>
//             <MaintenancePage />
//         </ThemeCustomization>
//     );
// };

// export default App;