// third-party
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';

// assets
import { IconChartArcs, IconClipboardList, IconChartInfographic,IconBuildingSkyscraper,IconInfoCircle,IconSearch,IconBulbOff, IconCalendarStats, IconAlertTriangle, IconPoint } from '@tabler/icons';

// import customChartArcs from '../assets/images/logo.svg';
// import { Icon } from 'react-icons';

// const customChartArcs = require('../assets/images/logo.svg');
// import { ReactComponent as CustomChartArcs } from '../assets/images/logo.svg';



// constant
const icons = {
  IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
    IconBuildingSkyscraper,
    IconInfoCircle,
    IconSearch,
    IconBulbOff,
    IconCalendarStats,
    IconAlertTriangle,
    IconPoint
};


// ==============================|| WIDGET MENU ITEMS ||============================== //

const widget = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    icon: icons.IconBuildingSkyscraper,
    type: 'group',
    children: [
        // SAP PENDINGS
        // {
        //     id: 'consulta_pago_de_facturas',
        //     title: <FormattedMessage id="consulta_pago_de_facturas" />,
        //     type: 'item',
        //     url: 'consulta_pago_de_facturas',            
        //     icon: icons.IconInfoCircle
        // },
        {
            id: 'citas',
            title: <FormattedMessage id="citas" />,
            type: 'item',
            url: 'citas',
            icon: icons.IconInfoCircle
        },
        {
            id: 'gestiones',
            title: <FormattedMessage id="gestiones" />,
            type: 'item',
            url: 'gestiones',
            icon: icons.IconSearch
        },
        ,
        {
            id: 'consumo_en_linea',
            title: <FormattedMessage id="consumo_en_linea" />,
            type: 'item',
            url: 'consumo_en_linea',
            icon: icons.IconCalendarStats
        }
        ,
        // {
        //     id: 'solicitud_nuevos_servicios',
        //     title: <FormattedMessage id="solicitud_nuevos_servicios" />,
        //     type: 'item',
        //     url: 'solicitud_nuevos_servicios',
        //     icon: icons.IconAlertTriangle
        // }
        // ,
        {
            id: 'simulador_de_consumo',
            title: <FormattedMessage id="simulador_de_consumo" />,
            type: 'item',
            url: 'simulador_de_consumo',
            icon: icons.IconChartInfographic
        }
        ,
        {
            id: 'mis_datos',
            title: <FormattedMessage id="mis_datos" />,
            type: 'item',
            url: 'mis_datos',
            icon: icons.IconChartInfographic
        },
        // {
        //     id: 'factura_electronica',
        //     title: <FormattedMessage id="factura_electronica" />,
        //     type: 'item',
        //     url: 'factura_electronica',
        //     icon: icons.IconPoint
        // },
        // {
        //     id: 'descarga_factura_electronica',
        //     title: <FormattedMessage id="descarga_factura_electronica" />,
        //     type: 'item',
        //     url: 'descarga_factura_electronica',
        //     icon: icons.IconPoint
        // }

    ]
};
console.log(widget);
export default widget;
