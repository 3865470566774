// third-party
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';

// assets
import { IconChartArcs, IconClipboardList, IconChartInfographic,IconBuildingSkyscraper,IconInfoCircle,IconSearch,IconBulbOff, IconCalendarStats, IconAlertTriangle } from '@tabler/icons';
// import { Icon } from 'react-icons';

// constant
const icons = {
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
    IconBuildingSkyscraper,
    IconInfoCircle,
    IconSearch,
    IconBulbOff,
    IconCalendarStats,
    IconAlertTriangle
};

const CustomIcon = ({ src, fallbackIcon }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [iconStyle, setIconStyle] = useState(null);
  
    useEffect(() => {
      const fetchIcon = async () => {
        try {
          const response = await fetch(src);
          if (!response.ok) {
            throw new Error('Icon fetch failed');
          }
          const blob = await response.blob();
          const iconURL = URL.createObjectURL(blob);
          setIconStyle({ backgroundImage: `url(${iconURL})` });
          setLoading(false);
        } catch (error) {
          console.error('Error fetching icon:', error);
          setLoading(false);
          setError(true);
        }
      };
  
      fetchIcon();
    }, [src]);
  
    if (loading) {
      return null; // Opcionalmente, puedes mostrar un componente de carga aquí
    }
  
    if (error) {
      return fallbackIcon; // Opcionalmente, puedes mostrar un componente de error aquí
    }
  
    return <div className="custom-icon" style={iconStyle} />;
  };

// ==============================|| WIDGET MENU ITEMS ||============================== //

const widget = {
    id: 'widget',
    title: <FormattedMessage id="widget" />,
    icon: icons.IconBuildingSkyscraper,
    type: 'group',
    children: [
    //   {
    //     id: 'factura_electronica',
    //     title: <FormattedMessage id="factura_electronica" />,
    //     type: 'item',
    //     url: 'facturacion-electronica',            
    // },
        {
            id: 'oficom',
            title: <FormattedMessage id="oficom" />,
            type: 'item',
            url: 'agencias-puntos-de-pago-aes',            
        },
        {
            id: 'noticias',
            title: <FormattedMessage id="noticias" />,
            type: 'item',
            url: '/noticias-aes',
            // icon: icons.IconInfoCircle
        },
        // // SAP PENDINGS
        // {
        //     id: 'denuncias',
        //     title: <FormattedMessage id="denuncias" />,
        //     type: 'item',
        //     url: '/denuncias',
        //     // icon: icons.IconSearch
        // },
        // ,
        // {
        //     id: 'interrupciones',
        //     title: <FormattedMessage id="interrupciones" />,
        //     type: 'item',
        //     url: '/interrupciones',
        //     // icon: icons.IconCalendarStats
        // }
        // ,
        // SAP PENDINGS
        // {
        //     id: 'peligros-en-la-red',
        //     title: <FormattedMessage id="peligros-en-la-red" />,
        //     type: 'item',
        //     url: '/peligros-en-la-red',
        //     // icon: icons.IconAlertTriangle
        // }
        // ,
        {
            id: 'innovacion',
            title: <FormattedMessage id="innovacion" />,
            type: 'item',
            url: '/innovacion',
            // icon: icons.IconChartInfographic
        }
        ,
        {
            id: 'descargas',
            title: <FormattedMessage id="descargas" />,
            type: 'item',
            url: 'descargas',
            // icon: icons.IconChartInfographic
        },

    ]
};

export default widget;
