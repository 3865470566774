import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));


const InitialView = Loadable(lazy(() => import('views/home')));
const MapsView = Loadable(lazy(() => import('views/business_offices')));
const NewsView = Loadable(lazy(() => import('views/aes_news')));
const TheftComplaintsView = Loadable(lazy(() => import('views/theft_complaints')));
const ScheduledOutagesView = Loadable(lazy(() => import('views/scheduled_outages')));
const DangerReportsView = Loadable(lazy(() => import('views/danger_reports')));
const InnovationIdeasView = Loadable(lazy(() => import('views/innovation_ideas')));
const DownloadsView = Loadable(lazy(() => import('views/downloads')));
const EnqueueView = Loadable(lazy(() => import('views/enq')));
const PolicyPrivacyTerms = Loadable(lazy(() => import('views/policy')));
const FacelecForm = Loadable(lazy(() => import('views/facelec')));
const FacelecFormFAQ = Loadable(lazy(() => import('views/facelec/faqindex')));

// widget routing
// const WidgetStatistics = Loadable(lazy(() => import('views/widget/Statistics')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <MainLayout />
    ),
    children: [
        // {
        //     path: '/facturacion-electronica',
        //     element: <FacelecForm />
        // },
        {
            path: '/agencias-puntos-de-pago-aes',
            element: <MapsView />
        },  
        {
            path: '/noticias-aes',
            element: <NewsView />
        }, 
        // SAP PENDINGS 
        // {
        //     path: '/denuncias',
        //     element: <TheftComplaintsView />
        // },  
        // {
        //     path: '/interrupciones',
        //     element: <ScheduledOutagesView />
        // },  
        {
            path: '/peligros-en-la-red',
            element: <DangerReportsView />
        },  
        {
            path: '/innovacion',
            element: <InnovationIdeasView />
        },  
        {
            path: '/descargas',
            element: <DownloadsView />
        },                       
        {
            path: '/inicio',
            element: <InitialView />
        },
        {
            path: '/enq',
            element: <EnqueueView />
        },
        {
            path: '/politica-uso-privacidad',
            element: <PolicyPrivacyTerms />
        },
        {
            path: '/facturacion_electronica/preguntas_frecuentes',
            element: <FacelecFormFAQ />
        }
        ,
        {
            path: '/factura_electronica',
            element: <FacelecForm />
        }
        
    ]
};

export default MainRoutes;
